
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "settlement-offer-table-component",
  components: {},
  props: {
    policySummary: Object,
  },
  data() {
    return {
      displayRemarkForm: false,
    };
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
